<template>
  <div style="padding: 20px">
    <div>
      <el-row :gutter="20">
        <el-col :span="12" :offset="0">
          <el-radio-group
            @change="handleStatusChange"
            v-model="status"
            style="margin-bottom: 10px;"
          >
            <el-radio-button label="all">全部</el-radio-button>
            <el-radio-button :label="1">已启用</el-radio-button>
            <el-radio-button :label="-1">未启用</el-radio-button>
          </el-radio-group>
        </el-col>
        <el-col :span="12" :offset="0">
          <div style="text-align: right">
            <el-button type="primary" size="default" @click="showAdd">
              新增
            </el-button>
          </div>
        </el-col>
      </el-row>
    </div>
    <el-table :data="list" border stripe row-key="id">
      <el-table-column label="分类名称" prop="title_cn"></el-table-column>
      <el-table-column label="分类名称（英）" prop="title_en"></el-table-column>
      <el-table-column label="作品数量" prop="count"></el-table-column>
      <el-table-column label="排序编号" prop="sort"></el-table-column>
      <el-table-column label="启用状态" prop="status">
        <template slot-scope="scope">
          <el-tag :type="scope.row.status === 1 ? 'success' : 'info'" size="normal">
            {{ scope.row.status === 1 ? "启用中" : "未启用" }}
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column label="添加时间" prop="created_at">
        <template slot-scope="scope">
          <div>{{ scope.row.created_at | formatDate }}</div>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="250" align="center">
        <template slot-scope="scope">
          <el-button
            size="mini"
            type="primary"
            @click="showEdit(scope.row)"
            style="margin-right: 10px"
          >
            编辑
          </el-button>

          <!-- <DeleteButton :id="`${scope.row.id}`" @success="fetchData" /> -->
        </template>
      </el-table-column>
    </el-table>

    <div style="margin-top: 20px">
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="page"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>

    <el-dialog
      :title="optionType === 'add' ? '新增分类' : '编辑分类'"
      :visible.sync="visible"
      width="600px"
      @close="closeDialog"
      destroy-on-close
    >
      <div v-if="visible">
        <CategoryForm :data="editCategory" :type="optionType" @success="handleSuccess" />
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getNFTCategoryList } from "@/api/nft.js";
import dayjs from "dayjs";
import CategoryForm from "./categoryForm.vue";
// import DeleteButton from "./deleteButton.vue";

export default {
  components: {
    CategoryForm,
    // DeleteButton,
  },
  data() {
    return {
      page: 1,
      pageSize: 20,
      total: 0,
      status: "all",
      list: [],
      visible: false,
      editCategory: {},
      optionType: "",
    };
  },
  mounted() {
    this.fetchData();
  },
  filters: {
    formatDate: function(value) {
      return dayjs(value * 1000).format("YYYY-MM-DD HH:mm:ss");
    },
  },
  methods: {
    async fetchData() {
      try {
        const params = {
          page: this.page,
          page_size: this.pageSize,
        };

        // 状态
        if (this.status !== "all") {
          params.status = +this.status;
        }

        const { data } = await getNFTCategoryList(params);

        const { total = 0, items = [] } = data?.data;
        this.total = total;
        this.list = [...items];
      } catch (error) {
        console.log(error);
        // mock
        // this.total = 100;
        // this.list = [
        //   {
        //     id: "6171068d1d41c86e91d3b6b3",
        //     title_cn: "文学",
        //     title_en: "Literature",
        //     sort: 11,
        //     status: -1,
        //     count: 100,
        //     created_at: 1634797197,
        //   },
        //   {
        //     id: "6171068d1d41c86e91d3b6b4",
        //     title_cn: "艺术",
        //     title_en: "Art",
        //     sort: 10,
        //     status: 1,
        //     count: 100,
        //     created_at: 1634797197,
        //   },
        // ];
      }
    },

    closeDialog() {
      this.visible = false;
      this.editCategory = {};
      this.optionType = "";
    },
    showEdit(row) {
      this.visible = true;
      this.optionType = "edit";
      this.editCategory = { ...row };
    },
    showAdd() {
      this.visible = true;
      this.optionType = "add";
      this.editCategory = {};
    },

    handleCurrentChange(page) {
      this.page = page;
      this.fetchData();
    },
    handleSizeChange(pageSize) {
      this.pageSize = pageSize;
      this.fetchData();
    },
    handleStatusChange() {
      this.pageSize = 20;
      this.page = 1;
      this.fetchData();
    },
    handleSuccess() {
      this.fetchData();
      this.closeDialog();
    },
  },
};
</script>

<style lang="less" scoped></style>
