<template>
  <div>
    <el-form
      :model="form"
      ref="form"
      :rules="rules"
      :inline="false"
      label-position="top"
      size="normal"
    >
      <el-form-item label="封面图" prop="icon">
        <el-upload
          list-type="picture-card"
          :on-success="handleUploadSuccess"
          :on-remove="handleRemove"
          :file-list="picList"
          :limit="1"
          accept="image/png,image/jpg,image/jpeg"
          action="https://www.ddpurse.com/uploadImage"
        >
          <i class="el-icon-plus"></i>
          <div slot="tip" class="el-upload__tip">只能上传JPG\PNG\JPEG文件，且不超过10M</div>
        </el-upload>
      </el-form-item>

      <el-form-item label="分类标题" prop="title_cn">
        <el-input v-model="form.title_cn"></el-input>
      </el-form-item>

      <el-form-item label="分类标题（英）" prop="title_en">
        <el-input v-model="form.title_en"></el-input>
      </el-form-item>

      <el-form-item label="分类描述" prop="description_cn">
        <el-input
          type="textarea"
          :rows="3"
          maxlength="100"
          show-word-limit
          v-model="form.description_cn"
        ></el-input>
      </el-form-item>

      <el-form-item label="分类描述（英）" prop="description_en">
        <el-input
          type="textarea"
          :rows="3"
          maxlength="100"
          show-word-limit
          v-model="form.description_en"
        ></el-input>
      </el-form-item>

      <el-form-item label="排序编号" prop="sort">
        <el-input v-model="form.sort"></el-input>
      </el-form-item>

      <el-form-item label="状态" prop="status" v-if="type === 'edit'">
        <div>
          <el-radio-group v-model="form.status">
            <el-radio :label="1">启用</el-radio>
            <el-radio :label="-1">禁用</el-radio>
          </el-radio-group>
        </div>
      </el-form-item>

      <el-form-item>
        <el-button type="primary" @click="onSubmit">
          {{ type == "add" ? "立即创建" : "确认修改" }}
        </el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { updateNFTCategory, createNFTCategory } from "@/api/nft.js";

export default {
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    type: {
      type: String,
      default: "add",
    },
  },
  data() {
    return {
      form: {
        status: -1,
      },
      rules: {
        icon: [{ required: true, message: "请上传封面图" }],
        title_cn: [{ required: true, message: "请输入标题", trigger: "blur" }],
        title_en: [{ required: true, message: "请输入英文标题", trigger: "blur" }],
        description_cn: [{ required: true, message: "请输入描述，最多100个字", trigger: "blur" }],
        description_en: [{ required: true, message: "请输入描述，最多100个字", trigger: "blur" }],
        sort: [{ required: true, message: "请输入排序编号", trigger: "blur" }],
      },
      picList: [],
    };
  },
  mounted() {
    if (this.type === "edit") {
      this.form = Object.assign({}, this.data);
    }
    if (this.data.icon) {
      this.picList = [
        {
          url: this.data.icon,
        },
      ];
    }
  },
  methods: {
    onSubmit() {
      console.log("onSubmit", { ...this.form });

      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.submit();
        } else {
          return false;
        }
      });
    },
    async submit() {
      console.log("submit", { ...this.form });
      const action = this.type === "add" ? this.add : this.update;
      const success = await action();
      if (success) {
        this.$emit("success");
      }
    },

    async add() {
      try {
        const data = this.getCategoryData();
        await createNFTCategory(data);
        return true;
      } catch (error) {
        return false;
      }
    },
    async update() {
      try {
        const id = this.form.id;
        const data = this.getCategoryData();
        await updateNFTCategory({
          ...data,
          id,
        });
        return true;
      } catch (error) {
        return false;
      }
    },

    getCategoryData() {
      const data = {
        icon: this.form.icon,
        title_cn: this.form.title_cn,
        title_en: this.form.title_en,
        description_cn: this.form.description_cn,
        description_en: this.form.description_en,
        sort: +this.form.sort,
        status: this.form.status,
      };

      if (this.type === "add") {
        return data;
      } else {
        return {
          ...data,
          status: +this.form.status,
        };
      }
    },
    // 上传和删除
    handleUploadSuccess(res, file) {
      this.form.icon = res.data;
      this.$refs.form.clearValidate("icon");
    },
    handleRemove(file, fileList) {
      this.form.icon = "";
    },
  },
};
</script>
